export const ORIGIN = window.location.origin
export const API_BASE_URL = ORIGIN + '/api'

export enum RESPOND_CODES {
  RESPONSE_SUCC = 0,
  ERROR_AGENT_NOT_FOUND = 131800018
}

export const COUNTRY_DOMAIN_SUFFIX = {
  ID: 'co.id'
}

export const LIST_PAGE_SIZE = 10
