import React from 'react'
import BreadCrumbWidget from '@/components/BreadCrumbWidget'
import style from './index.module.less'
import { getEnv } from '@/utils'

export default function BreadCrumbWarning() {
  const env = getEnv()
  return (
    <BreadCrumbWidget>
      <div className={style.container}>
        <div className={style.content}>
          <span style={{ color: 'red' }}>Migration to ECHO: </span>
          <span>
            You can search, relabel, remove videos in the new platform ECHO. Click{' '}
            <a href={`https://tns.sv${env ? '.' + env : ''}.shopee.co.id/echo`} target='_blank' rel='noreferrer'>
              HERE
            </a>{' '}
            to start your search.
          </span>
        </div>
      </div>
    </BreadCrumbWidget>
  )
}
