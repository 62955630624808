import React, { lazy } from 'react'
import Icon from '@ant-design/icons'
import { RawRoute } from './types'
import { ReactComponent as BusinessSVG } from '@/icons/svg/business.svg'
import { ExportType, UploadRecordType, PERMISSIONS } from '@/constants'
// import { PERMISSIONS } from '@/constants'

const PolicyManagement = {
  path: '/policy',
  icon: <Icon component={BusinessSVG} />,
  text: 'Policy Management',
  children: [
    {
      path: '/old-label',
      text: 'Label 1.0',
      component: lazy(() => import(/* webpackChunkName: "label-management" */ '@/pages/Policy/Label'))
    },
    {
      path: '/label',
      text: 'Label 2.0',
      component: React.lazy(() => import(/* webpackChunkName: "label-management" */ '@/pages/Policy/Label2.0')),
      children: [
        {
          path: '/record',
          permissions: [PERMISSIONS.read],
          text: 'Export Record',
          meta: {
            exportType: ExportType.Label
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        },
        {
          path: '/upload-record',
          permissions: [PERMISSIONS.read],
          meta: {
            recordType: UploadRecordType.CrawlHashtag
          },
          children: [
            {
              path: '/value',
              permissions: [PERMISSIONS.write],
              text: 'Upload Record',
              meta: {
                recordType: UploadRecordType.BatchAddLabelEntityTagValue
              },
              component: React.lazy(() => import(/* webpackChunkName: "upload-record" */ '@/pages/UploadRecord'))
            },
            {
              path: '/attribute',
              permissions: [PERMISSIONS.write],
              text: 'Upload Record',
              meta: {
                recordType: UploadRecordType.BatchAddLabelEntityTagAttribute
              },
              component: React.lazy(() => import(/* webpackChunkName: "upload-record" */ '@/pages/UploadRecord'))
            },
            {
              path: '/l1',
              permissions: [PERMISSIONS.write],
              text: 'Upload Record',
              meta: {
                recordType: UploadRecordType.BatchAddLabelEntityL1Label
              },
              component: React.lazy(() => import(/* webpackChunkName: "upload-record" */ '@/pages/UploadRecord'))
            },
            {
              path: '/l2',
              permissions: [PERMISSIONS.write],
              text: 'Upload Record',
              meta: {
                recordType: UploadRecordType.BatchAddLabelEntityL2Label
              },
              component: React.lazy(() => import(/* webpackChunkName: "upload-record" */ '@/pages/UploadRecord'))
            }
          ]
        }
      ]
    }
  ]
} as RawRoute

export default PolicyManagement
