/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/rules-of-hooks */
import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios'
import { message } from 'antd'
import Qs from 'qs'
import { API_BASE_URL, errorCodeMap } from '@/constants'
import { SortOrder } from 'antd/lib/table/interface'
import { normalizeKey } from '@/utils'
// import { RegionList } from '@/models/region'
// import { isArray } from '@/utils/type'
// import RegionStore from '@/stores/region'
// import { reaction } from 'mobx'

type ReqInterceptors = {
  reWriteConfig: (config: AxiosRequestConfig) => AxiosRequestConfig
  errHandler: (error: any) => void
}[]

type ResInsterceptors = {
  reWriteRes: (res: AxiosResponse) => AxiosResponse
  errHandler: (error: any) => void
}[]

export type ResData<D> = {
  code: number
  data: D
  msg: string
}

export type BaseOptionsFetcherParams = {
  query?: string
  page: number
  pageSize?: number
}

// 请求表格数据的参数，请确保后端用的是这些参数
export type BaseTableFetcherParams = {
  query?: string
  sortFields?: string // 后端是可以传多个，用 , 分隔， 但是目前只能按一个来排序，所以不用传多个
  sortValues?: SortOrder // 升序/降序， 后端是可以传多个，用 , 分隔
  pageSize?: number
  page?: number
  filterFields?: string // filter_fields=key1,key2,key2
  filterValues?: string // filter_values=v1,v2,v3
}

export type BaseInfocardFetcherParams = {
  filter_fields?: string // 查询select的选项域
  filter_values?: string // 查询select选项域对应的values值
}

export type BaseTableFetcherRes<Item> = {
  total: number
  list: Item[]
}

export type ExtendsTableFetcherParams<T extends Record<string, any>> = BaseTableFetcherParams & T

type PreRequestsMap = Map<string, (response: AxiosResponse<ResData<any>>, instance: AxiosInstance) => void>
class Service {
  static serviceInstance: AxiosInstance
  static preRequestsMap: PreRequestsMap
  static preRequestPromise: Promise<void>
  static preRequestResolveFun: () => void
  static preRequestRejectFun: (reason?: string) => void
  static whiteList: string[]
  constructor({
    baseURL,
    preRequests,
    whiteList,
    requestInterceptors,
    responseInterceptors
  }: {
    baseURL: string
    preRequests: PreRequestsMap
    whiteList: string[]
    requestInterceptors: ReqInterceptors
    responseInterceptors: ResInsterceptors
  }) {
    Service.serviceInstance = axios.create({
      baseURL: baseURL || API_BASE_URL,
      timeout: 60000,
      paramsSerializer: (params) => {
        return Qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })

    Service.preRequestsMap = preRequests
    Service.whiteList = whiteList
    Service.preRequestPromise = new Promise((resolve, reject) => {
      Service.preRequestResolveFun = resolve
      Service.preRequestRejectFun = reject
    })
    Service.filterPreRequest()
    // reaction(
    //   () => RegionStore.regionId,
    //   (id) => {
    //     Service.handleRegionChange(id)
    //   }
    // )

    Service.useInterceptReq(requestInterceptors)
    Service.useInterceptRes(responseInterceptors)
  }

  // 订阅 region 改变，若更改则更新 haeader 中地区信息
  static handleRegionChange(regionId: string) {
    const regionLower = regionId.toLowerCase()
    const currentRegion = Service.serviceInstance.defaults.headers.common['Region']
    if (regionLower !== currentRegion) {
      Service.serviceInstance.defaults.headers.common['Region'] = regionLower
    }
  }

  static filterPreRequest() {
    Service.serviceInstance.interceptors.request.use(async (config) => {
      const { url = '' } = config
      // 非 preRequest 需等待所有 preRequest 完成
      if (Service.preRequestsMap.size && !Service.preRequestsMap.has(url) && Service.whiteList.indexOf(url) < 0) {
        await Service.preRequestPromise
      }
      return config
    })

    Service.serviceInstance.interceptors.response.use((response) => {
      // preRequests 若有任意请求错误，则 promise 会一直处于 pending 状态，走统一的错误处理。其他请求将无法发送
      if (Service.preRequestsMap.size > 0) {
        const { url = '' } = response.config
        const callbackFun = Service.preRequestsMap.get(url)
        if (callbackFun) {
          callbackFun(response, Service.serviceInstance)
          Service.preRequestsMap.delete(url)
        }

        if (Service.preRequestsMap.size <= 0) {
          Service.preRequestResolveFun()
        }
      }
      return response
    })
  }

  static useInterceptReq(requestInterceptors: ReqInterceptors) {
    requestInterceptors.forEach(({ reWriteConfig, errHandler }) => {
      Service.serviceInstance.interceptors.request.use(
        (config) => reWriteConfig(config),
        (err) => {
          errHandler(err)
        }
      )
    })
  }

  static useInterceptRes(responseInterceptors: ResInsterceptors) {
    responseInterceptors.forEach(({ reWriteRes, errHandler }) => {
      Service.serviceInstance.interceptors.response.use(
        (response) => reWriteRes(response),
        (err) => {
          errHandler(err)
        }
      )
    })
  }

  public async get(params: AxiosRequestConfig) {
    const { url, data, ...config } = params
    const resData = await Service.serviceInstance.get(url!, {
      params: data,
      ...config
    })
    //@ts-ignore
    return resData as ResData<any>
  }

  public async post(params: AxiosRequestConfig) {
    const { url, data, ...config } = params
    const resData = await Service.serviceInstance.post(url!, data, config)
    //@ts-ignore
    return resData as ResData<any>
  }

  public async delete(params: AxiosRequestConfig) {
    const { url, data, ...config } = params
    const resData = await Service.serviceInstance.delete(url!, {
      data,
      ...config
    })
    //@ts-ignore
    return resData as ResData<any>
  }

  public async put(params: AxiosRequestConfig) {
    const { url, data, ...config } = params
    const resData = await Service.serviceInstance.put(url!, data, config)
    //@ts-ignore
    return resData as ResData<any>
  }
}

const service = new Service({
  baseURL: API_BASE_URL,
  preRequests: new Map([
    // [
    //   '/v1/agent/regions',
    //   (response: AxiosResponse<ResData<RegionList>>, instance: AxiosInstance) => {
    //     const data = response.data.data
    //     const { regions } = normalizeKey.underlineToCamelCase(data) as typeof data
    //     const { regionId } = RegionStore
    //     const findResult =
    //       isArray(regions) && regions.length === 1 ? regions[0] : regions.find((item) => item.shortName === regionId)
    //     if (findResult) {
    //       instance.defaults.headers.common['Region'] = findResult.shortName.toLowerCase()
    //     }
    //     RegionStore.setRegionId(findResult?.shortName ?? '')
    //   }
    // ]
  ]),
  whiteList: ['/v1/agent/logout'],
  requestInterceptors: [
    {
      reWriteConfig: (config) => {
        return {
          ...config,
          params: config.params ? normalizeKey.camelCaseToUnderline(config.params) : undefined,
          data: config.data ? normalizeKey.camelCaseToUnderline(config.data) : undefined
        }
      },
      errHandler: (err) => {
        // console.error('request err:', err)
        message.warning(err.message)
        return err
      }
    }
  ],
  responseInterceptors: [
    {
      reWriteRes: (res) => {
        const { code, msg } = res.data as ResData<any>
        const result = normalizeKey.underlineToCamelCase(res.data)
        if (code === 0) {
          if (result && result.data?.loginUrl) {
            window.location = result.data.loginUrl
            return
          }
          return result
        } else {
          if (errorCodeMap[code]) {
            message.error(errorCodeMap[code])
          } else {
            message.error(msg)
          }
          // console.error('response err:', { code, msg })
          throw new Error(
            JSON.stringify({
              code,
              msg
            })
          )
        }
      },
      errHandler: (err) => {
        const res = err.response
        if (res?.status === 401) {
          // 需要登录
          const loginUrl = res?.data?.soup_login_url
          return (window.location.href = loginUrl)
        } else if (res?.status === 403) {
          // ‘/task’页面隐藏没有权限的入口，不需要弹窗提示
          if (window.location.pathname !== '/task') {
            // 登陆了但是没有权限
            message.warn('you have no permission')
          }
        } else if (res?.data?.code && errorCodeMap[res.data.code]) {
          message.error(errorCodeMap[res?.data?.code])
        } else {
          message.warning(err?.message)
        }
        // console.error('response err:', err)
        throw new Error(err)
      }
    }
  ]
})

const api = {
  get: service.get,
  post: service.post,
  delete: service.delete,
  put: service.put
} as const

export default api
