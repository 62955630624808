import { Auth } from '@/models/user'
import { getUserPermission, login } from '@/network/user'
import { action, makeAutoObservable, runInAction } from 'mobx'
import { PERMISSIONS } from '@/constants'
class User {
  email = 'chege.qumu@shopee.com'
  photo = ''
  ssoToken = ''
  userid: string | number | null = null
  username = ''
  permission: string[] = [PERMISSIONS.read, PERMISSIONS.write]
  isLoading = true

  constructor() {
    makeAutoObservable(this, {
      getUserInfo: action.bound
    })
  }

  async getUserInfo() {
    this.isLoading = true
    const result = await Promise.all([login(), getUserPermission()])
    runInAction(() => {
      const auth: Auth = result[0]
      this.email = auth.email
      this.photo = auth.photo || ''
      this.ssoToken = auth.ssoToken
      this.userid = auth.userid
      this.username = auth.username
      this.permission = result[1]
      this.isLoading = false
    })
  }
  resetCurUser() {
    this.email = 'chege.qumu@shopee.com'
    this.photo = ''
    this.ssoToken = ''
    this.userid = ''
    this.username = ''
    this.permission = [PERMISSIONS.read, PERMISSIONS.write]
    this.isLoading = true
  }
}

const user = new User()
console.log(user)
export default user
