export type ValueOf<T> = T[keyof T]
// export type ObjKeyValueUnion<T extends Record<any, any>> = ValueOf<{
//   [K in keyof T]: T[K]
// }>

// type Values = ValueOf<{a: number, b: string}>
// type KeyValues = ObjKeyValueUnion<{a: number, b: string}>

export const getType = (data: any) => {
  return Object.prototype.toString.call(data)
}

export const isArray = (data: any) => getType(data) === '[object Array]'

type FixTsUnion<T, K extends keyof T> = {
  [Prop in keyof T]?: Prop extends K ? T[Prop] : never
}
export type oneOf<T> = {
  [K in keyof T]: Pick<T, K> & FixTsUnion<T, K>
}[keyof T]

type Push<T extends any[], V> = [...T, V]
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never
type LastOf<T> = UnionToIntersection<
  T extends any ? () => T : never
> extends () => infer R
  ? R
  : never

// 从联合类型 =》 元组类型
export type TuplifyUnion<
  T,
  L = LastOf<T>,
  N = [T] extends [never] ? true : false
> = true extends N ? [] : Push<TuplifyUnion<Exclude<T, L>>, L>
