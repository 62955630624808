import { useCallback } from 'react'
import { Popover, Layout } from 'antd'
import { loginOut } from '@/network/user'
import headerStyle from './styles/Header.module.less'
import { ReactComponent as AvatarDefault } from '@/icons/svg/avatar.svg'
import { observer } from 'mobx-react-lite'
import user from '@/stores/user'

const { Header } = Layout

function AppHeader() {
  const { photo } = user

  const handleLoginOut = useCallback(async () => {
    try {
      await loginOut()
      window.location.reload()
    } catch (err) {
      console.log({ err })
    }
  }, [])

  const popover = (
    <div className={headerStyle.userPopover}>
      <div onClick={handleLoginOut} className={headerStyle.userPopoverItem}>
        Sign out
      </div>
    </div>
  )

  return (
    <Header className={headerStyle.headerConteiner}>
      <div className={headerStyle.rightItem}>
        <Popover
          content={popover}
          trigger='click'
          color='white'
          placement='bottomRight'
          overlayStyle={{
            padding: 0,
            width: '10rem',
            textAlign: 'center',
            margin: 0
          }}
        >
          {<div className={headerStyle.photo}>{photo ? <img src={photo} alt='' /> : <AvatarDefault />}</div>}
        </Popover>
      </div>
    </Header>
  )
}

export default observer(AppHeader)
