type StorageCluster = {
  clusterId: string
  cluster: {
    [key: string]: string
  }
}
// 慎用 localStorage，大小有限制
export class StorageDb {
  static storage = window.localStorage
  protected clusterId: string
  constructor(clusterId: string) {
    this.clusterId = clusterId
  }

  static _getCluster(clusterId: string): StorageCluster {
    const clusterStr: string | null = StorageDb.storage.getItem(clusterId)
    let clusterObj: StorageCluster
    if (!clusterStr) {
      clusterObj = { clusterId, cluster: {} }
      StorageDb._setCluster(clusterId, clusterObj)
    } else {
      clusterObj = JSON.parse(clusterStr)
    }
    return clusterObj
  }

  static _setCluster(clusterId: string, cluster: StorageCluster) {
    try {
      StorageDb.storage.setItem(clusterId, JSON.stringify(cluster))
    } catch (err) {
      __DEV__ && console.log(err)
    }
  }

  public set(key: string, value: string) {
    const cluster = StorageDb._getCluster(this.clusterId)
    cluster.cluster[key] = value
    StorageDb._setCluster(this.clusterId, cluster)
  }

  public get(key: string) {
    const cluster = StorageDb._getCluster(this.clusterId)
    return cluster.cluster[key]
  }

  public remove(key: string) {
    const cluster = StorageDb._getCluster(this.clusterId)
    delete cluster.cluster[key]
    StorageDb._setCluster(this.clusterId, cluster)
  }

  public clear() {
    StorageDb.storage.removeItem(this.clusterId)
  }
}
