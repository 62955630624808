import * as API from '@/network/violationType'
import { action, makeAutoObservable, runInAction } from 'mobx'

interface TypeMap {
  [key: number]: string
}
class ViolationModel {
  newViolationMap: TypeMap = {}
  allViolationMap: TypeMap = {}
  constructor() {
    makeAutoObservable(this, {
      getViolationMap: action.bound
    })
  }
  async getViolationMap() {
    const [resNotIncludeOld, resIncludeOld] = await Promise.all([
      API.getViolationType({ includeOld: false }),
      API.getViolationType({ includeOld: true })
    ])
    const newMap: TypeMap = {}
    const allMap: TypeMap = {}
    resNotIncludeOld.data.list.forEach((item) => {
      newMap[item.violationId] = item.violationName
    })
    resIncludeOld.data.list.forEach((item) => {
      allMap[item.violationId] = item.violationName
    })
    runInAction(() => {
      this.newViolationMap = newMap
      this.allViolationMap = allMap
    })
  }
}

export default new ViolationModel()
