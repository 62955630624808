import React, { useMemo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useInitedRouters } from './context'

// import RegionStore from '@/stores/region'
// import styles from './styles/content.module.less'

// enum REGION_PROMPT_TEXT {
//   NO_SELECT = 'Please select region above',
//   REQUEST_ERROR = 'Failed to get Region info. Please refresh this page to try again.'
// }

export default observer(function RouteContent() {
  const { flattedRouters } = useInitedRouters()
  // const { regionInfo, regionRequestState } = RegionStore

  // const getComponent = useCallback(
  //   (Component: React.LazyExoticComponent<() => JSX.Element>) => {
  //     return regionInfo && Component ? (
  //       <div key={regionInfo?.shortName}>
  //         <Component />
  //       </div>
  //     ) : (
  //       <div className={styles.regionText}>
  //         {regionRequestState === 'rejected' ? REGION_PROMPT_TEXT.REQUEST_ERROR : REGION_PROMPT_TEXT.NO_SELECT}
  //       </div>
  //     )
  //   },
  //   [regionInfo, regionRequestState]
  // )

  const routeTemplate = useMemo(() => {
    return flattedRouters.map(({ path, component: Com, meta }) => {
      return Com && <Route key={path} path={path} element={<Com meta={meta} />} />
    })
  }, [flattedRouters])

  if (!routeTemplate.length) return null

  return (
    <Routes>
      {routeTemplate}
      <Route path='*' element={<Navigate to='/policy/label' />} />
    </Routes>
  )
})
