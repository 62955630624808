import React, { useCallback, useEffect, useMemo, useState, Suspense } from 'react'
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom'
import { Layout, Spin } from 'antd'
import AppHeader from './Header'
import AppMenu from './Menu'
import { routerCtx } from './context'
import Breadcrumb from './Breadcrumb'
import RouteContent from './Content'
import contentStyles from './styles/content.module.less'
import indexStyles from './styles/index.module.less'
import rawRoutes from '@/routes'
import { flattenRoutes } from '@/routes/utils'
import { observer } from 'mobx-react-lite'
import user from '@/stores/user'
import violationType from '@/stores/violationType'
import { QueryParamProvider } from 'use-query-params'
import { toJS } from 'mobx'
const { Content, Sider } = Layout
const isQiankun = !!window.__POWERED_BY_QIANKUN__

/**
 * This is the main thing you need to use to adapt the react-router v6
 * API to what use-query-params expects.
 *
 * Pass this as the `ReactRouterRoute` prop to QueryParamProvider.
 */
const RouteAdapter = ({ children }: any) => {
  const navigate = useNavigate()
  const location = useLocation()

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state })
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state })
      }
    }),
    [navigate]
  )
  return children({ history: adaptedHistory, location })
}

const ConnectRouters = observer(({ children }: { children: React.ReactChild }) => {
  const permissions = toJS(user.permission)
  const initedRoutres = flattenRoutes(rawRoutes, '', permissions)
  return (
    <routerCtx.Provider
      value={useMemo(
        () => ({
          ...initedRoutres
        }),
        [initedRoutres]
      )}
    >
      {children}
    </routerCtx.Provider>
  )
})

function MainLayout() {
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(false)
  const onCollapse = useCallback(() => {
    setIsSiderCollapsed((collapsed) => !collapsed)
  }, [])

  return (
    <Layout>
      <Sider theme='light' width={300} collapsible collapsed={isSiderCollapsed} onCollapse={onCollapse}>
        <AppMenu collapsed={isSiderCollapsed} />
      </Sider>
      <Layout className={indexStyles.content}>
        {isQiankun ? null : <AppHeader />}
        <Content className={contentStyles.contentContainer}>
          <Breadcrumb />
          <Suspense
            fallback={
              <div className={indexStyles.loading}>
                <Spin />
              </div>
            }
          >
            <RouteContent />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  )
}

function AppLayout() {
  const { isLoading, getUserInfo } = user
  const { getViolationMap } = violationType
  useEffect(() => {
    getUserInfo()
  }, [getUserInfo])
  useEffect(() => {
    getViolationMap()
  }, [getViolationMap])

  return (
    <Spin spinning={isLoading} size='large' style={{ height: '100vh' }}>
      {!isLoading ? (
        <Router basename={isQiankun ? '/video-safety-web' : '/'}>
          <QueryParamProvider ReactRouterRoute={RouteAdapter}>
            <ConnectRouters>
              <MainLayout />
            </ConnectRouters>
          </QueryParamProvider>
        </Router>
      ) : null}
    </Spin>
  )
}

export default observer(AppLayout)
