import { StorageDb } from '@/utils'

export const cacheRegion = (() => {
  const cache = new StorageDb('cacheRegion')

  const REGION_ID = 'region_id'

  const setRegion = (id: string) => {
    // id = shortName
    return cache.set(REGION_ID, String(id))
  }
  const getRegion = () => {
    return cache.get(REGION_ID) ?? ''
  }

  return {
    setRegion,
    getRegion
  }
})()
