import React from 'react'
import ReactDOM from 'react-dom'

import { BaseComponentProps } from '@/models/index'

const Portal: React.FunctionComponent<BaseComponentProps> = (props: BaseComponentProps) => {
  return ReactDOM.createPortal(
    props.children,
    document.querySelector('.layout-container__header__widget') || document.body
  )
}

export default Portal
