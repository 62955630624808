import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Menu } from 'antd'
import { RawRoute, RoutePath } from '@/routes/types'
import rawRoutes from '@/routes'
import { isRouterPermited } from '@/routes/utils'
import { useInitedRouters } from './context'
import styles from './styles/menu.module.less'
import user from '@/stores/user'
import { toJS } from 'mobx'
const isQiankun = !!window.__POWERED_BY_QIANKUN__

// import Region from './Region'
const { SubMenu, Item } = Menu

interface MenuOptionsType {
  openKeys: string[]
  selectedKeys: string[]
}

export default observer(function AppMenu({ collapsed }: { collapsed: boolean }) {
  const myPermissions = toJS(user.permission)
  const location = useLocation()
  const navigate = useNavigate()

  const [menuOptions, setMenuOptions] = useState<MenuOptionsType>({
    openKeys: [],
    selectedKeys: []
  })
  const { subMenusRouterKeys, menuKeys } = useInitedRouters()

  useEffect(() => {
    const pathItems = location.pathname.split('/')
    const maybeOpenKeys: string[] = []
    const maybeSelectedKeys: string[] = []

    pathItems.slice(1).reduce((prevPath, curPathItem) => {
      const path = prevPath + '/' + curPathItem
      subMenusRouterKeys.includes(path) && maybeOpenKeys.push(path)
      menuKeys.includes(path) && maybeSelectedKeys.push(path)
      return path
    }, '')

    setMenuOptions((prev) => {
      return {
        ...prev,
        openKeys: [...new Set(prev.openKeys.concat(maybeOpenKeys))],
        selectedKeys: maybeSelectedKeys
      }
    })
  }, [location.pathname, menuKeys, subMenusRouterKeys])

  const naviRouteContent = useCallback(
    (path: RoutePath) => {
      navigate(path)
    },
    [navigate]
  )

  const generateMenu = useCallback(
    (routes: RawRoute[], parentPath: RoutePath = '') => {
      return routes
        .map((route) => {
          const { icon, text, path, component, children, notRenderAsMenuLink } = route
          if (!notRenderAsMenuLink && isRouterPermited(route, myPermissions)) {
            if (children && !component) {
              return (
                <SubMenu key={parentPath + path} title={text} icon={icon}>
                  {generateMenu(children, path)}
                </SubMenu>
              )
            } else {
              return (
                <Item
                  key={parentPath + path}
                  onClick={() => naviRouteContent(parentPath + path)}
                  title={text}
                  icon={icon}
                >
                  <span>{text}</span>
                </Item>
              )
            }
          } else {
            return null
          }
        })
        .filter((i) => i)
    },
    [naviRouteContent, myPermissions]
  )

  const menus = useMemo(() => generateMenu(rawRoutes), [generateMenu])

  const handleOpenChange = useCallback((openKeys: string[]) => {
    setMenuOptions((prev) => {
      return {
        ...prev,
        openKeys
      }
    })
  }, [])

  const handleSelect = useCallback((item, key, keyPath, selectedKeys: string[]) => {
    setMenuOptions((prev) => {
      return {
        ...prev,
        selectedKeys
      }
    })
  }, [])
  const menuTitle = (
    <div className={styles['platform-title-area']}>
      <div className={styles['platform-title']}>{collapsed ? '' : 'Shopee'}</div>
      {/* <Region isShow={!collapsed} /> */}
    </div>
  )

  return (
    <>
      {isQiankun ? null : menuTitle}
      <Menu
        className='custom-menu-contaner'
        mode='inline'
        selectedKeys={menuOptions.selectedKeys}
        openKeys={menuOptions.openKeys}
        onOpenChange={handleOpenChange}
        onSelect={handleSelect as any}
      >
        {menus}
      </Menu>
    </>
  )
})
