/* eslint-disable no-param-reassign */
import dayjs from 'dayjs'

export const formatDate = (val: any, type = 'YYYY-MM-DD HH:mm:ss'): string => {
  if (Number(val)) {
    // val为字符串时间戳
    val = Number(val)
  }
  return dayjs(val).format(type)
}

const oneHour = 3600
const oneDay = oneHour * 24
export const fromNow = (val: any): string => {
  if (Number(val)) {
    val = Number(val)
  }
  const now = dayjs().unix()
  const last = dayjs(val).unix()
  const offset = now - last
  if (offset < oneHour) {
    return `${Math.floor(offset / 60)} minutes`
  }
  if (offset < oneDay) {
    return `${Math.floor(offset / oneHour)} hours`
  }
  return `${Math.floor(offset / oneDay)} days`
}

export const unix = (val: any) => {
  return dayjs(val).unix()
}

export const day2second = (day: number) => {
  return day * 24 * 60 * 60
}

export const minute2second = (minutes: number) => {
  return minutes * 60
}

export const second2minute = (second: number) => {
  return second / 60
}

export const second2day = (second: number) => {
  return second / 60 / 60 / 24
}

export const formatMinute = (microSeconds: number) => {
  const totalSeconds = Math.round(microSeconds / 1000)
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds - minutes * 60
  return `${minutes}:${seconds}`
}
// 修改秒数显示 不足10秒显示为2位
export const formatMinute2 = (microSeconds: number) => {
  const totalSeconds = Math.round(microSeconds / 1000)
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds - minutes * 60
  return seconds < 10 ? `${minutes}:0${seconds}` : `${minutes}:${seconds}`
}
