import { CF_URL } from '@/constants'
import { Sound, Music, ReportedMusic, CheckBoxItem, SelectOption } from '@/models'
import CSV from 'comma-separated-values'

export * from './object'
export * from './storage'
export * from './type'
export * from './date'
export * from './validator'
export * from './location'
export * from './time'
export * from './url'
export * from './getEnv'

export const noop = () => null
export const sleep = async (time: number) => {
  return new Promise((res) => {
    setTimeout(res, time)
  })
}

export const isType = (data: any, type: string) => {
  return Object.prototype.toString.call(data) === `[object ${type}]`
}

export const isArray = (data: any) => {
  return isType(data, 'Array')
}

export const isObject = (data: any) => {
  return isType(data, 'Object')
}

export const isFunction = (data: any) => {
  return isType(data, 'Function')
}

export const deepClone = (item: any) => {
  if (!item) {
    return item
  }

  const types = [Number, String, Boolean]
  let result: any

  types.forEach(function (type) {
    if (item instanceof type) {
      result = type(item)
    }
  })

  if (typeof result === 'undefined') {
    if (Object.prototype.toString.call(item) === '[object Array]') {
      result = []
      item.forEach(function (child: any, index: number) {
        result[index] = deepClone(child)
      })
    } else if (typeof item === 'object') {
      if (item.nodeType && typeof item.cloneNode === 'function') {
        result = item.cloneNode(true)
      } else if (!item.prototype) {
        if (item instanceof Date) {
          result = new Date(item)
        } else {
          result = {}
          for (const i in item) {
            result[i] = deepClone(item[i])
          }
        }
      } else {
        if (item.constructor) {
          result = new item.constructor()
        } else {
          result = item
        }
      }
    } else {
      result = item
    }
  }

  return result
}

const useHttps = window.location.protocol === 'https:'
export const convert2Https = (url: string) => {
  if (!url) return ''
  return useHttps ? url.replace('http://', 'https://') : url
}

export const downloadFromUrl = (url: string, filename = '') => {
  const link = document.createElement('a')
  link.href = url
  link.download = filename
  link.click()
}

export const downloadCSVFromData = (data: any[][], filename = '') => {
  if (data) {
    const encodeURIData = data.map((item) => item.map((item) => encodeURIComponent(item)))
    const content = CSV.encode(encodeURIData)
    const url = `data:text/csv;charset=utf-8,${content}`
    downloadFromUrl(url, filename)
  }
}

export const convertFilter2Map = (
  filters: {
    text: string
    value: number
  }[]
) => {
  return filters.reduce((prev: { [key: number]: string }, curr) => {
    prev[curr.value] = curr.text
    return prev
  }, {})
}

export const createFiltersByMap = (map: {
  [key: number]: string
}): {
  text: string
  value: number
}[] => {
  return Object.keys(map).map((key) => {
    return {
      text: map[Number(key)],
      value: Number(key)
    }
  })
}

export const createFiltersFromEnum = (map: any) => {
  return Object.keys(map)
    .filter((key) => !isNaN(Number(key)))
    .map((key) => {
      return {
        text: map[key],
        value: key
      }
    })
}

export const createOptionsByMap = (map: {
  [key: number]: string
}): {
  label: string
  value: number
}[] => {
  return Object.keys(map).map((key) => {
    return {
      label: map[Number(key)],
      value: Number(key)
    }
  })
}

export const createSelectOptions = (
  map: Record<any, any> = {},
  upper?: boolean
): {
  text: string
  value: number
}[] => {
  return Object.keys(map).map((key) => {
    return {
      text: upper ? key.toUpperCase() : key,
      value: map[key]
    }
  })
}

export const createCheckOptions = (
  map: Record<any, any> = {},
  upper?: boolean
): {
  label: string
  value: number
}[] => {
  return Object.keys(map)
    .map((key) => {
      return {
        label: upper ? key.toUpperCase() : key,
        value: map[key]
      }
    })
    .sort((a, b) => a.value - b.value)
}

export const getOptionText = (data: SelectOption[], value: number | string) => {
  const res = data.filter((item) => item.value === value)
  if (res.length) return res[0].text
  return ''
}

export const getOptionLabel = (data: CheckBoxItem[], value: number | string) => {
  const res = data.filter((item) => item.value === value)
  if (res.length) return res[0].label
  return ''
}

export const createSelectArrOptions = (arr: string[] = []) => {
  return arr.map((item: string) => {
    return {
      text: item,
      value: item
    }
  })
}

export const formatLabel = (arr: string[]) => {
  if (Array.isArray(arr) && arr.length) {
    const filterArr = arr.filter((item) => item !== '')
    if (filterArr.length === 0) {
      return '-'
    }
    return filterArr.join(' | ')
  }
}

export const getMusicTitle = (music: Sound | Music | ReportedMusic) => {
  const { title, authorName } = music
  return title || `original sound - ${authorName}`
}

export const createCfImageUrl = (cover: string) => {
  if (!cover) return ''
  if (cover.indexOf('https://') === 0 || cover.indexOf('http://') === 0) {
    return convert2Https(cover)
  }
  return `${CF_URL}/${cover}`
}
