import queryString from 'query-string'

export const parseUrl = (url: string) => queryString.parse(url, { arrayFormat: 'bracket-separator' })

export const stringifyUrl = (obj: Record<string, any>) =>
  queryString.stringify(obj, { arrayFormat: 'bracket-separator' })

export const syncUrl = (obj: Record<string, any>) => {
  let search = parseUrl(window.location.search)
  search = {
    ...search,
    ...obj
  }
  window.history.replaceState(null, '', `?${stringifyUrl(search)}`)
}

// 解析地址栏url中query参数
export const getQueryParamByKey = (paramName: string): string => {
  let url = document.location.toString()
  // 如果url中有特殊字符则需要进行一下解码
  url = decodeURI(url)
  const arrObj = url.split('?')
  if (arrObj.length > 1) {
    const arrPara = arrObj[1].split('&')
    let arr
    for (let i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split('=')
      if (arr != null && arr[0] === paramName) {
        return decodeURIComponent(arr[1])
      }
    }
    return ''
  } else {
    return ''
  }
}
