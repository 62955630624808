import { createContext, useContext } from 'react'
import { FlattenRoute, RoutePath } from '@/routes/types'

const defaultRouters: {
  flattedRouters: FlattenRoute[]
  subMenusRouterKeys: RoutePath[]
  menuKeys: RoutePath[]
} = {
  flattedRouters: [],
  subMenusRouterKeys: [],
  menuKeys: []
}
export const routerCtx = createContext(defaultRouters)
export const useInitedRouters = () => useContext(routerCtx)
