import './public-path'
import '@/styles/tailwind.css'
import '@/styles/index.less'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

// 包裹之前的 render 处理
function render() {
  ReactDOM.render(<App />, document.getElementById('root')) // 需要在第一行
}

if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

export async function bootstrap() {
  console.log('app bootstraped')
}

export async function mount() {
  render()
}

export async function unmount() {
  ReactDOM.unmountComponentAtNode(document.getElementById('root')!)
}
