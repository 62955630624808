import api from './http'
import type { ResData } from './http'

export interface ViolationType {
  violationId: number
  violationName: string
}

interface ViolationReq {
  includeOld: boolean
}
export const getViolationType = (params: ViolationReq): Promise<ResData<{ list: ViolationType[]; total: number }>> =>
  api.get({
    params,
    url: '/violation/list'
  })
