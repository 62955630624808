import { makeAutoObservable, runInAction } from 'mobx'
import { IRegionItem } from '@/models/region'
import { cacheRegion } from '@/storage/region'
import { getRegionList } from '@/network/region'

class Region {
  regionId: string = cacheRegion.getRegion()
  regionMap: IRegionItem[] = []
  regionRequestState: 'pending' | 'resolved' | 'rejected' = 'pending'

  constructor() {
    makeAutoObservable(this)
  }

  get regionInfo() {
    return this.regionMap.find((item) => item.shortName === this.regionId)
  }

  setRegionId = (id: string) => {
    if (id !== this.regionId) {
      this.regionId = id
      cacheRegion.setRegion(id)
    }
  }

  getRegionMap = async () => {
    getRegionList()
      .then((res) => {
        const { regions } = res.data
        runInAction(() => {
          this.regionMap = [...regions]
          this.regionRequestState = 'resolved'
        })
      })
      .catch(() => {
        runInAction(() => {
          this.regionRequestState = 'rejected'
        })
      })
  }
}

export default new Region()
