import { Breadcrumb } from 'antd'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useInitedRouters } from './context'
import { RoutePath, RouteTitle } from '@/routes/types'
import { Link } from 'react-router-dom'
import breadcrumbStyles from './styles/breadcrumb.module.less'
import { observer } from 'mobx-react-lite'
import user from '@/stores/user'
import BreadCrumbWarning from '@/components/BreadCrumbWarning'

type PathSetItem = {
  path: RoutePath
  title: RouteTitle
  search?: string
  hasComponenet: boolean
}
function AppBreadcrumb() {
  const [pathSet, setPathSet] = useState<PathSetItem[]>([])
  const location = useLocation()
  const permissions = user.permission
  const { flattedRouters } = useInitedRouters()
  useEffect(() => {
    const { pathname } = location
    const paths = pathname.split('/').slice(1)
    const _pathSet: PathSetItem[] = []
    paths.reduce((prevPath, subPath) => {
      const _path = prevPath + '/' + subPath
      const matched = flattedRouters.find(({ path }) => {
        // 去除 path params
        const reg = new RegExp('^' + path.replace(/\/:[^/]+/g, '/[^/]+') + '$')
        const isMatch = reg.test(_path)
        return isMatch
      })
      if (matched && matched.text) {
        _pathSet.push({
          path: _path,
          title: matched.text,
          hasComponenet: !!matched.component
        })
        return _path
      }
      return _path
    }, '')
    setPathSet(_pathSet)
  }, [flattedRouters, location, permissions])

  return (
    <div className={breadcrumbStyles.container}>
      <Breadcrumb className={breadcrumbStyles.breadcrumb}>
        {pathSet.map(({ path, title, hasComponenet, search }) => (
          <Breadcrumb.Item key={path}>
            {hasComponenet ? <Link to={{ pathname: path, search }}>{title}</Link> : <span>{title}</span>}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <div className='layout-container__header__widget' />
      <BreadCrumbWarning />
    </div>
  )
}

export default observer(AppBreadcrumb)
