import AppLayout from '@/layout'
function App() {
  return (
    <div className='App safety'>
      <AppLayout />
    </div>
  )
}

export default App
