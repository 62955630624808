import { RawRoute, FlattenRoute, RoutePath } from './types'

export const isRouterPermited = (route: RawRoute, permissions: string[]): boolean => {
  return (
    !route.permissions || // 不需要 permission
    route.permissions.every((item) => permissions.includes(item))
  )
}

export const flattenRoutes = (
  routes: RawRoute[],
  parentPath: RoutePath = '',
  permissions: string[]
): {
  flattedRouters: FlattenRoute[]
  subMenusRouterKeys: RoutePath[]
  menuKeys: RoutePath[]
} => {
  return routes.reduce(
    (
      {
        flattedRouters,
        subMenusRouterKeys,
        menuKeys
      }: {
        flattedRouters: FlattenRoute[]
        subMenusRouterKeys: RoutePath[]
        menuKeys: RoutePath[]
      },
      curRoute
    ) => {
      if (!isRouterPermited(curRoute, permissions)) {
        return {
          flattedRouters,
          subMenusRouterKeys,
          menuKeys
        }
      }
      const curPath = parentPath + curRoute.path
      let newFlatten: FlattenRoute[] = [...flattedRouters]
      let newSubMenusRouterKeys: RoutePath[] = [...subMenusRouterKeys]
      let newMenuKeys: RoutePath[] = [...menuKeys]
      if (curRoute.component) {
        newFlatten = newFlatten.concat({
          ...curRoute,
          path: curPath
        })
        newMenuKeys = !curRoute.notRenderAsMenuLink ? [...menuKeys, curPath] : menuKeys
      }
      if (curRoute.children) {
        const {
          flattedRouters: _flattedRouters,
          subMenusRouterKeys: _subMenusRouterKeys,
          menuKeys: _menuKeys
        } = flattenRoutes(curRoute.children, curPath, permissions)

        if (!curRoute.notRenderAsMenuLink) {
          const isAllChildrenNotRenderAsMenuLink = curRoute.children.every(
            ({ notRenderAsMenuLink }) => notRenderAsMenuLink
          )
          if (isAllChildrenNotRenderAsMenuLink) {
            //render as menu
            newMenuKeys = [...newMenuKeys, curPath]
          } else {
            //render as subMenu
            newSubMenusRouterKeys = [...subMenusRouterKeys, curPath].concat(_subMenusRouterKeys)
          }
        }
        newMenuKeys = [...newMenuKeys, ..._menuKeys]
        if (!curRoute.component) {
          newFlatten = newFlatten.concat({
            ...curRoute,
            path: curPath
          })
        }
        newFlatten = newFlatten.concat(_flattedRouters)
      }
      return {
        flattedRouters: newFlatten,
        subMenusRouterKeys: newSubMenusRouterKeys,
        menuKeys: newMenuKeys
      }
    },
    {
      flattedRouters: [],
      subMenusRouterKeys: [],
      menuKeys: []
    }
  )
}
