import { Auth } from '@/models/user'
import api from './http'

export const loginOut = () => {
  return api.post({
    url: '/v1/agent/logout'
  })
}
export const login = async () => {
  const res = await api.get({ url: '/auth/login' })
  return res.data as Auth
}

export const getUserPermission = async () => {
  const res: any = await api.get({ url: '/auth/perms' })
  return res?.data?.perms ? res.data.perms : []
}
