export enum UploadRecordType {
  UgcVideo = 14,
  SupplierVideo = 15,
  ReportVideo = 16,
  MachineQcFailedVideo = 20,
  ReportUser = 27,
  ReportComment = 28,
  ReportMusic = 29,
  ReportHashtag = 30,
  ReportReply = 31,
  TrendingVideo = 32,
  CrawlUser = 36,
  CrawlHashtag = 37,
  CrawlTrendingHashtag = 38,
  UserProfile = 39,
  AddBotMapping = 40,
  RemoveBotMapping = 41,
  CrawlingTrendingTopic = 42,
  KolUserWhiteList = 43,
  UploadProtectUser = 44,
  BatchUploadSound = 45,
  BatchAddLabelEntityL1Label = 46,
  BatchAddLabelEntityL2Label = 47,
  BatchAddLabelEntityTagAttribute = 48,
  BatchAddLabelEntityTagValue = 49,
  CreatorList = 50
}

export enum ExportType {
  Video = 1,
  User = 2,
  Like = 3,
  Follow = 4,
  Comment = 5,
  Reply = 6,
  Music = 7,
  ReportUser = 8,
  ReportMusic = 9,
  ReportVideo = 10,
  ReportComment = 11,
  VideoQCPending = 12,
  SupplyVideoQCPending = 13,
  VideoQcUpload = 14,
  SupplyVideoQcUpload = 15,
  ReportVideoUpload = 16,
  ReportVideoCompleted = 17,
  VideoQCCompleted = 18,
  SupplyVideoQCCompleted = 19,
  VideoFailedMachineQcUpload = 20,
  VideoFailedMachineQcPending = 21,
  VideoFailedMachineQcCompleted = 22,
  ReportUserPending = 24,
  ReportCommentPending = 25,
  ReportMusicPending = 26,
  ReportHashtagPending = 30,
  ReportHashtagCompleted = 31,
  ReplyList = 32,
  ReportReplyPending = 33,
  ReportReplyCompleted = 34,
  PendingTrendingTopic = 35,
  CompletedTrendingTopic = 36,
  CrawlingCreaterPending = 40,
  CrawlingCreaterVerified = 41,
  CrawlPendingHashtag = 42,
  CrawlCompletedHashtag = 43,
  CrawlTrendingHashtagCrawled = 45,
  Label = 44,
  UserProfilePending = 46,
  UserProfileCompleted = 47,
  BotMapping = 48,
  CrawlingTopic = 50,
  CrawledTopic = 51,
  SurveyData = 52,
  HashtagList = 53,
  HashtagDetail = 54,
  BannerAction = 60,
  MagicPoolFullList = 61,
  AnchorManagement = 62,
  SoundClassification = 66,
  SoundList = 67,
  SoundPool = 68
}
