import React, { lazy } from 'react'
import Icon from '@ant-design/icons'
import { RawRoute } from './types'
import { ReactComponent as BusinessSVG } from '@/icons/svg/business.svg'
import { ExportType, UploadRecordType, PERMISSIONS } from '@/constants'

const PolicyManagement = {
  path: '/qc-management',
  icon: <Icon component={BusinessSVG} />,
  text: 'QC Management',
  children: [
    {
      path: '/video',
      text: 'UGC Video Passed Machine QC',
      component: React.lazy(() => import(/* webpackChunkName: "video-qc" */ '@/pages/Video/QC')),
      children: [
        {
          path: '/pending-record',
          permissions: [PERMISSIONS.read],
          text: 'Export Record',
          meta: {
            exportType: ExportType.VideoQCPending
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        },
        {
          path: '/upload-record',
          permissions: [PERMISSIONS.read],
          text: 'Upload Record',
          meta: {
            recordType: UploadRecordType.UgcVideo
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/UploadRecord'))
        },
        {
          path: '/completed-record',
          permissions: [PERMISSIONS.read],
          text: 'Export Record',
          meta: {
            exportType: ExportType.VideoQCCompleted
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        }
      ]
    },

    {
      path: '/failed-machine-video',
      text: 'UGC Videos Failed Machine QC',
      component: React.lazy(
        () => import(/* webpackChunkName: "video-failed-machine-qc" */ '@/pages/Video/FailedMachineQC')
      ),
      children: [
        {
          path: '/pending-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.VideoFailedMachineQcPending
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        },
        {
          path: '/upload-record',
          text: 'Upload Record',
          permissions: [PERMISSIONS.read],
          meta: {
            recordType: UploadRecordType.MachineQcFailedVideo
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/UploadRecord'))
        },
        {
          path: '/completed-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.VideoFailedMachineQcCompleted
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        }
      ]
    },
    {
      path: '/supply-video',
      text: 'Supplier Videos QC',
      component: React.lazy(() => import(/* webpackChunkName: "supply-qc" */ '@/pages/Video/SupplyQC')),
      children: [
        {
          path: '/pending-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.SupplyVideoQCPending
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        },
        {
          path: '/upload-record',
          text: 'Upload Record',
          permissions: [PERMISSIONS.read],
          meta: {
            recordType: UploadRecordType.SupplierVideo
          },
          component: React.lazy(() => import(/* webpackChunkName: "upload-record" */ '@/pages/UploadRecord'))
        },
        {
          path: '/completed-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.SupplyVideoQCCompleted
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        }
      ]
    },
    {
      path: '/trending',
      text: 'Trending Topic QC',
      component: React.lazy(() => import(/* webpackChunkName: "trending-qc" */ '@/pages/Video/Trending')),
      children: [
        {
          path: '/pending-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.PendingTrendingTopic
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        },
        {
          path: '/upload-record',
          text: 'Upload Record',
          permissions: [PERMISSIONS.read],
          meta: {
            recordType: UploadRecordType.TrendingVideo
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/UploadRecord'))
        },
        {
          path: '/completed-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.CompletedTrendingTopic
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        }
      ]
    },
    {
      path: '/user-profile-qc',
      text: 'User Profile QC',
      component: React.lazy(() => import(/* webpackChunkName: "report-comment" */ '@/pages/User/ProfileQC')),
      children: [
        {
          path: '/completed-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.UserProfileCompleted
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        },
        {
          path: '/pending-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.UserProfilePending
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        },
        {
          path: '/upload-record',
          text: 'Upload Record',
          permissions: [PERMISSIONS.read],
          meta: {
            recordType: UploadRecordType.UserProfile
          },
          component: React.lazy(() => import(/* webpackChunkName: "upload-record" */ '@/pages/UploadRecord'))
        }
      ]
    },
    {
      path: '/report-video',
      text: 'Reported Videos',
      component: React.lazy(() => import(/* webpackChunkName: "report-content" */ '@/pages/Video/Report')),
      children: [
        {
          path: '/pending-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.ReportVideo
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        },
        {
          path: '/upload-record',
          text: 'Upload Record',
          permissions: [PERMISSIONS.read],
          meta: {
            recordType: UploadRecordType.ReportVideo
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/UploadRecord'))
        },
        {
          path: '/completed-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.ReportVideoCompleted
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        }
      ]
    },
    {
      path: '/report-comment',
      text: 'Reported Comments',
      component: React.lazy(() => import(/* webpackChunkName: "report-comment" */ '@/pages/Comment/Report')),
      children: [
        {
          path: '/completed-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.ReportComment
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        },
        {
          path: '/pending-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.ReportCommentPending
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        },
        {
          path: '/upload-record',
          text: 'Upload Record',
          permissions: [PERMISSIONS.read],
          meta: {
            recordType: UploadRecordType.ReportComment
          },
          component: React.lazy(() => import(/* webpackChunkName: "upload-record" */ '@/pages/UploadRecord'))
        }
      ]
    },
    {
      path: '/report-reply',
      text: 'Reported Reply',
      component: React.lazy(() => import(/* webpackChunkName: "report-reply" */ '@/pages/Reply/Report')),
      children: [
        {
          path: '/completed-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.ReportReplyCompleted
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        },
        {
          path: '/pending-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.ReportReplyPending
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        },
        {
          path: '/upload-record',
          text: 'Upload Record',
          permissions: [PERMISSIONS.read],
          meta: {
            recordType: UploadRecordType.ReportReply
          },
          component: React.lazy(() => import(/* webpackChunkName: "upload-record" */ '@/pages/UploadRecord'))
        }
      ]
    },
    {
      path: '/report-sound',
      component: React.lazy(() => import(/* webpackChunkName: "report-sound" */ '@/pages/Sound/Report')),
      text: 'Reported Sound Page',
      children: [
        {
          path: '/completed-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.ReportMusic
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        },
        {
          path: '/pending-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.ReportMusicPending
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        },
        {
          path: '/upload-record',
          text: 'UploadRecord',
          permissions: [PERMISSIONS.read],
          meta: {
            recordType: UploadRecordType.ReportMusic
          },
          component: React.lazy(() => import(/* webpackChunkName: "upload-record" */ '@/pages/UploadRecord'))
        }
      ]
    },
    {
      path: '/report-user',
      component: React.lazy(() => import(/* webpackChunkName: "user-report" */ '@/pages/User/Report')),
      text: 'Reported Users',
      children: [
        {
          path: '/pending-record',
          component: React.lazy(() => import(/* export-record */ '@/pages/ExportRecord')),
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.ReportUserPending
          }
        },
        {
          path: '/completed-record',
          component: React.lazy(() => import(/* export-record */ '@/pages/ExportRecord')),
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.ReportUser
          }
        },
        {
          path: '/upload-record',
          component: React.lazy(() => import(/* upload-record */ '@/pages/UploadRecord')),
          text: 'Upload Record',
          permissions: [PERMISSIONS.read],
          meta: {
            recordType: UploadRecordType.ReportUser
          }
        }
      ]
    },
    {
      path: '/report-hashtag',
      component: React.lazy(() => import(/* webpackChunkName: "report-sound" */ '@/pages/Hashtag/Report')),
      text: 'Reported Hashtag',
      children: [
        {
          path: '/pending-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.ReportHashtagPending
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        },
        {
          path: '/upload-record',
          text: 'Upload Record',
          permissions: [PERMISSIONS.read],
          meta: {
            recordType: UploadRecordType.ReportHashtag
          },
          component: React.lazy(() => import(/* webpackChunkName: "upload-record" */ '@/pages/UploadRecord'))
        },
        {
          path: '/completed-record',
          text: 'Export Record',
          permissions: [PERMISSIONS.read],
          meta: {
            exportType: ExportType.ReportHashtagCompleted
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/ExportRecord'))
        }
      ]
    },
    {
      path: '/account-protection',
      component: React.lazy(() => import(/* webpackChunkName: "report-sound" */ '@/pages/User/Protection')),
      text: 'Account Protection',
      permissions: [PERMISSIONS.protectUserRead, PERMISSIONS.protectUserWrite],
      children: [
        {
          path: '/upload-log',
          text: 'Upload Log',
          permissions: [PERMISSIONS.read],
          meta: {
            recordType: UploadRecordType.UploadProtectUser
          },
          component: React.lazy(() => import(/* webpackChunkName: "export-record" */ '@/pages/UploadRecord'))
        },
        {
          path: '/change-log',
          text: 'Change Log',
          permissions: [PERMISSIONS.read],
          component: React.lazy(
            () => import(/* webpackChunkName: "upload-record" */ '@/pages/User/Protection/ChangeLog')
          )
        }
      ]
    },
    {
      path: '/hide-comment',
      component: React.lazy(() => import(/* webpackChunkName: "comment-list" */ '@/pages/Comment/Hidden')),
      text: 'Hide Comment and Reply',
      meta: {}
    }
  ]
} as RawRoute

export default PolicyManagement
