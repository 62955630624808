import { reaction } from 'mobx'
import RegionStore from '@/stores/region'

class TimezoneCalculator {
  public currentZone: number
  public regionZone: number
  constructor() {
    this.regionZone = this.currentZone = this.getCurrentZone()
    this.updateRegionZone()
    reaction(
      () => RegionStore.regionInfo?.timeZone,
      timezone => {
        this.updateRegionZone(timezone)
      }
    )
  }
  private updateRegionZone(timezone?: string) {
    this.regionZone = Number(
      timezone ?? this.currentZone
    )
  }
  private getCurrentZone() {
    return (new Date().getTimezoneOffset() / 60) * -1
  }
  public getTimezoneDate(
    date: number,
    targetZone?: number,
    currentZone?: number
  ) {
    const target = targetZone ?? this.regionZone
    const current = currentZone ?? this.currentZone
    const diff = (current - target) * 60 * 60 * 1000
    return date - diff
  }
}

const timezoneInstance = new TimezoneCalculator()

export default timezoneInstance
